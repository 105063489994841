import React, { useEffect, useState } from "react";
import axios from "axios";
import Sidebar from "../Sidebar/sidebar";
import Select from "react-select";
import "react-toastify/dist/ReactToastify.css";
import { Circles } from "react-loader-spinner";
import { toast } from "react-toastify";
import "./website.css";
import { Footer } from "../Footer/footer";
import { useNavigate } from "react-router-dom";
import { Button, Card, CardHeader, Modal } from "react-bootstrap";
import {countryOptions,languageOptions} from "../../utils/constants"

function AddWebsite() {
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [typesDataDropdown, setTypesDataDropdown] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [linkStatusOptions, setsLinkStatusOptions ]=useState([])

  const handleShowModal = () => {
    setShowCategoryModal(true);
  };
  const handleCloseModal = () => {
    setShowCategoryModal(false);
  };

 

  const navigate = useNavigate();
  const [name, setName] = useState("");

  const [categoriesDropdownData, setCategoriesDropdownData] = useState([]);
  const [teamMemberDropdownData, setTeamMemberDropdownData] = useState([]);
  const [addDataModal, setAddDataModal] = useState({
    url: "",
    contactEmail: "",
    emailUsed: "",
    teamMember: [],
    domainRating: 1,
    domainAuthority: 1,
    spamScore: 1,
    priceGP: 1,
    priceLI: 1,
    authorityScore: 1,
    linkStatus: { value: " ", label: "" },
    ahrefs: 1,
    semrush: 1,
    type: [],
    country: "",
    language: "",
    slackCommunity: "",
    currencyGP: "",
  });

  const categoriesDropdownOptions = [];
  categoriesDropdownData.map((type) =>
    categoriesDropdownOptions.push({
      value: type._id,
      label: type.categoryName,
    })
  );
  const handleCountryDropdownChange = (fieldName, selectedOption) => {
    setAddDataModal((prevaddDataModal) => ({
      ...prevaddDataModal,
      [fieldName]: selectedOption,
    }));
  };

  const getLinkStatus=()=>{

  }
  
  const handleLanguageDropdownChange = (fieldName, selectedOption) => {
    setAddDataModal((prevaddDataModal) => ({
      ...prevaddDataModal,
      [fieldName]: selectedOption,
    }));
  };

  const typesDropdownOptions = [];
  typesDataDropdown.map((type) =>
    typesDropdownOptions.push({ value: type._id, label: type.TypeName })
  );

  const handleCategoryDropdownChange = (fieldName, selectedOption) => {
    if (Array.isArray(selectedOption)) {
      const exists = selectedOption.some(
        (obj) => obj.label === "+ Add New Category"
      );
      console.log(selectedOption, "exist not");
      if (exists) {
        handleShowModal();
        if (selectedOption?.length > 1) {
          setAddDataModal((prevaddDataModal) => ({
            ...prevaddDataModal,
          }));
        } else {
          setAddDataModal((prevaddDataModal) => ({
            ...prevaddDataModal,
            [fieldName]: [],
          }));
        }
      } else {
        setAddDataModal((prevaddDataModal) => ({
          ...prevaddDataModal,
          [fieldName]: selectedOption,
        }));
      }
    } else {
      console.log("exist not");
      setAddDataModal((prevaddDataModal) => ({
        ...prevaddDataModal,
        [fieldName]: selectedOption,
      }));
    }
  };
  useEffect(() => {
    // listWebsites();
    // listTypes();
    listTypesDropdown();
    listStatusDropdown();
    listCategoriesDropdown();
    listTeamMemberDropdown();
  }, []);
  console.log(typesDataDropdown, "kakka");

  const listTypesDropdown = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_API}/api/type/list-all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setTypesDataDropdown(res.data.result);
      })
      .catch((err) => toast.error(err.response.data.message));
  };

  
  const listStatusDropdown = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_API}/api/link/list-all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setsLinkStatusOptions(res.data.result.map((linkStatus)=>(
        {
          value:linkStatus._id,
          label:linkStatus.linkStatus
        }  
        )))
      })
      .catch((err) => toast.error(err.response.data.message));
  };
  const listTeamMemberDropdown = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_API}/api/user/list-all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setTeamMemberDropdownData(res.data.result);
      })
      .catch((err) => toast.error(err.response.data.message));
  };
  const listCategoriesDropdown = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_API}/api/category/list-all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setCategoriesDropdownData(res.data.result);
        console.log("xxxxxx");

        res?.data?.result.forEach((element) => {
          console.log("yyyy", selectedOption);

          if (element.categoryName == name) {
            console.log("zzzzzzzzzzzzzzzzzzzzzzzzzz");

            let tempArr = addDataModal.category;
            tempArr.push({ value: element._id, label: element.categoryName });
            setAddDataModal({ ...addDataModal, category: tempArr });
          }
        });
        setName("");
      })
      .catch((err) => toast.error(err));
  };

  const handleAddModalChange = (event) => {
    const { name, value } = event.target;
    setAddDataModal((prevaddDataModal) => ({
      ...prevaddDataModal,
      [name]: value,
    }));
  };
  

  const handleAddSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const linkStatus = addDataModal.linkStatus;
    const formData = {
      ...addDataModal,
      linkStatus: linkStatus.value,
      country: addDataModal.country.value,
      language: addDataModal.language.value,
      category: addDataModal.category,
      type: addDataModal.type,
      teamMember: addDataModal.teamMember.value,
    };

    axios
      .post(`${process.env.REACT_APP_SERVER_API}/api/web/add`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setData([...data, res.data]);
        toast.success("Website Added Successfully");

        setLoading(false);
        // Reset the addDataModal state to initial values
        setAddDataModal({
          url: "",
          contactEmail: "",
          emailUsed: "",
          teamMember: "",
          domainRating: 0,
          domainAuthority: 0,
          spamScore: 0,
          priceGP: 0,
          priceLI: 0,
          authorityScore: 0,
          linkStatus: { value: "Request Status", label: "Request Status" },
          ahrefs: 0,
          semrush: 0,
          type: [],
          country: "",
          language: "",
          slackCommunity: "",
          currencyGP: "",
        });
        handleBack();
      })
      .catch((err) => {
        if (err.response && err.response.status === 400) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message);
        }
        setLoading(false);
      });
  };
  const teamMemberDropdownOptions = [];
  teamMemberDropdownData.map((type) =>
    teamMemberDropdownOptions.push({ value: type._id, label: type.name })
  );
  const categoriesOptions = [];
  categoriesData.map((type) =>
    categoriesOptions.push({
      value: type.parent._id,
      label: type.parent.categoryName,
    })
  );
  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  const handleAddCategory = (e) => {
    e.preventDefault();
    setLoading(true);

    const parentValue = selectedOption ? selectedOption.value : null;
    console.log(selectedOption, "exiswww", selectedOption);

    axios
      .post(
        `${process.env.REACT_APP_SERVER_API}/api/category/add`,
        {
          categoryName: name,
          parent: parentValue,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        // setData([...data, res.data]);

        toast.success("Category Added Succesfully");
        handleCloseModal();
        setLoading(false);
        setSelectedOption({});
        listCategoriesDropdown();
      })
      .catch((err) => toast.error(err.response.data.message))
      .finally(() => setLoading(false));
  };

  const handleBack = () => {
    navigate("/websites");
  };

  return (
    <div className="d-flex">
      <Sidebar />
      <div
        className="container-fluid "
        style={{ height: "100vh", overflow: "auto" }}
      >
        <div className="row m-0">
          <Circles
            height="80"
            width="80"
            color="#3155a7"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass="loading-spinner-overlay"
            visible={loading}
          />

          <>
            <form onSubmit={handleAddSubmit}>
              <div className="mt-3">
                <h3>Add Website</h3>
              </div>
              <Card className="mt-2" style={{ marginBottom: "70px" }}>
                <CardHeader></CardHeader>
                <div className="mb-3">
                  <div className="row m-0">
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                      <label htmlFor="URL" className="form-label">
                        URL *
                      </label>
                      <input
                        input
                        type="url"
                        id="url"
                        className="form-control"
                        name="url"
                        required
                        value={addDataModal?.url}
                        pattern="^https?:\/\/\S+$"
                        placeholder="Please enter a valid URL with http or https://"
                        title="Please enter a valid URL with http or https://"
                        onChange={(event) => handleAddModalChange(event)}
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                      <label htmlFor="email" className="form-label">
                        Contact Email *
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="contactEmail"
                        value={addDataModal?.contactEmail}
                        onChange={(event) => handleAddModalChange(event)}
                        required
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                      <label htmlFor="email" className="form-label">
                        Email Used *
                      </label>
                      <input
                        type="email"
                        name="emailUsed"
                        className="form-control"
                        id="emailUsed"
                        value={addDataModal?.emailUsed}
                        onChange={(event) => handleAddModalChange(event)}
                        required
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                      <label htmlFor="team-member" className="form-label">
                        Team Member *
                      </label>
                      <Select
                        required
                        options={teamMemberDropdownOptions}
                        value={addDataModal?.teamMember}
                        onChange={(selectedOption) =>
                          handleCategoryDropdownChange(
                            "teamMember",
                            selectedOption
                          )
                        }
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                      <label htmlFor="domainRating" className="form-label">
                        Domain Rating *
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="domainRating"
                        name="domainRating"
                        value={addDataModal?.domainRating}
                        min="0"
                        max="100"
                        step="1"
                        onChange={(event) => handleAddModalChange(event)}
                        required
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                      <label htmlFor="domainRating" className="form-label">
                        Domain Authority *
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="domainAuthority"
                        name="domainAuthority"
                        value={addDataModal?.domainAuthority}
                        min="0"
                        max="100"
                        step="1"
                        onChange={(event) => handleAddModalChange(event)}
                        required
                      />
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                      <label htmlFor="gp_price" className="form-label">
                        GP Price
                      </label>
                      <input
                        type="number"
                        name="priceGP" // Changed from gp_price to priceGP
                        className="form-control"
                        id="gp_price"
                        value={addDataModal?.priceGP}
                        min="0"
                        step="1"
                        onChange={(event) => handleAddModalChange(event)}
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                      <label htmlFor="currencyGP" className="form-label">
                        Currency
                      </label>

                      <input
                        type="text"
                        className="form-control"
                        id="currencyGP"
                        name="currencyGP" // Changed from gp_currency to currencyGP
                        value={addDataModal?.currencyGP}
                        onChange={(event) => handleAddModalChange(event)}
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                      <label htmlFor="price" className="form-label">
                        LI Price
                      </label>
                      <input
                        type="number"
                        name="priceLI" // Changed from price to priceLI
                        className="form-control"
                        id="price"
                        value={addDataModal?.priceLI}
                        min="0"
                        step="1"
                        onChange={(event) => handleAddModalChange(event)}
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                      <label htmlFor="authorityScore" className="form-label">
                        Authority Score *
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="authorityScore"
                        name="authorityScore"
                        value={addDataModal?.authorityScore}
                        min="0"
                        max="100"
                        step="1"
                        onChange={(event) => handleAddModalChange(event)}
                        required
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                      <label htmlFor="spamScore" className="form-label">
                        Spam Score *
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        name="spamScore"
                        id="price"
                        value={addDataModal?.spamScore}
                        min="0"
                        max="100"
                        step="1"
                        onChange={(event) => handleAddModalChange(event)}
                        required
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                      <label htmlFor="linkStatus" className="form-label">
                        Link Status *
                      </label>
                      <Select
                        required
                        options={linkStatusOptions}
                        value={addDataModal?.linkStatus}
                        onChange={(selectedOption) =>
                          handleCategoryDropdownChange(
                            "linkStatus",
                            selectedOption
                          )
                        }
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                      <label htmlFor="domainRating" className="form-label">
                        Ahrefs Traffic *
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="Hrefs"
                        name="ahrefs"
                        value={addDataModal?.ahrefs}
                        onChange={(event) => handleAddModalChange(event)}
                        required
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                      <label htmlFor="domainRating" className="form-label">
                        Semrush Traffic *
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        id="Semrush"
                        name="semrush"
                        value={addDataModal?.semrush}
                        onChange={(event) => handleAddModalChange(event)}
                        required
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                      <label htmlFor="Categories" className="form-label">
                        Categories *
                      </label>
                      <Select
                        required
                        isMulti
                        options={[
                          {
                            label: "+ Add New Category",
                            value: "static_option",
                          },
                          ...categoriesDropdownOptions,
                        ]}
                        value={addDataModal?.category}
                        onChange={(selectedOption) =>
                          handleCategoryDropdownChange(
                            "category",
                            selectedOption
                          )
                        }
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                      <label htmlFor="Types" className="form-label">
                        Types *
                      </label>

                      <Select
                        required
                        isMulti
                        options={typesDropdownOptions}
                        value={addDataModal?.type}
                        onChange={(selectedOption) =>
                          handleCategoryDropdownChange("type", selectedOption)
                        }
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                      <label htmlFor="Types" className="form-label">
                        Country *
                      </label>
                      <Select
                        required
                        options={countryOptions}
                        name="country"
                        value={addDataModal.country}
                        onChange={(selectedOption) =>
                          handleCountryDropdownChange("country", selectedOption)
                        }
                        placeholder="Country"
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                      <label htmlFor="Types" className="form-label">
                        Language *
                      </label>
                      <Select
                        required
                        options={languageOptions}
                        name="language"
                        value={addDataModal.language}
                        onChange={(selectedOption) =>
                          handleLanguageDropdownChange("language", selectedOption)
                        }
                        placeholder="Language"
                      />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-2">
                      <label htmlFor="Types" className="form-label">
                        Slack Community *
                      </label>

                      <input
                        type="text"
                        className="form-control"
                        id="slackCommunity"
                        name="slackCommunity"
                        value={addDataModal?.slackCommunity}
                        onChange={(event) => handleAddModalChange(event)}
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className="d-flex justify-content-end my-3">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={handleBack}
                  >
                    Back
                  </button>
                  <button type="submit" className="mx-2 btn btn-primary">
                    Add Website
                  </button>
                </div>
              </Card>
            </form>

            <Modal show={showCategoryModal} onHide={handleCloseModal}>
              <Modal.Header closeButton>
                <Modal.Title>Add Category</Modal.Title>
              </Modal.Header>
              <form onSubmit={handleAddCategory}>
                <Modal.Body>
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">
                      Name *
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                    <label htmlFor="name" className="form-label mt-2">
                      Parent
                    </label>
                    <Select
                      options={categoriesDropdownOptions}
                      name="typeFilter"
                      value={selectedOption}
                      onChange={handleChange}
                      placeholder="Categories"
                    />
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    onClick={handleCloseModal}
                    type="button"
                    className="btn btn-secondary"
                  >
                    Close
                  </Button>
                  <Button type="submit" className="btn btn-primary">
                    Add Category
                  </Button>
                </Modal.Footer>
              </form>
            </Modal>
          </>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default AddWebsite;
