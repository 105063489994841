import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logout from "../Auth/logout";
import logo from "./logo.webp";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "./sidebarStyles.css";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";

const Sidebar = () => {
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState("");
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const handleToggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleSignOut = (e) => {
    e.preventDefault();
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    toast.success("Logout successfully");
    navigate("/");
  };
  
  const permission = localStorage.getItem("permission");

  const checkPermissions = (permissionName) => {
    return permission === permissionName;
  }
  

  return (
    <div
      id="sidebar"
      style={{ backgroundColor: "#3155a7", height: "100vh", overflowY: "auto" }}
      className={`col-auto col-sm-2 d-flex flex-column justify-content-between min-vh-100 adjustSidebar ${
        sidebarOpen ? "" : "collapsed"
      }`}
    >
      <div className="mt-2 d-flex flex-column" id="sidebar-elms">
        <Link
          className="text-decoration-none mt-2 text-center d-flex align-items-center text-white  d-sm-inline"
          role="button"
        >
          <img
            src={logo}
            alt="Rankviz logo"
            style={{ width: "25px", height: "25px" }}
          />
        </Link>
        <hr className="text-white d-none d-sm-block my-2"></hr>
        <div
          className="d-flex justify-content-center"
          style={{ marginTop: "16px", cursor: "pointer" }}
        >
          <span style={{ color: "white" }} onClick={handleToggleSidebar}>
            {sidebarOpen ? (
              <i className="bi bi-arrow-left-circle-fill"></i>
            ) : (
              <i className="bi bi-arrow-right-circle-fill"></i>
            )}
          </span>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <ul className="nav nav-pills flex-column mt-2 mt-sm-0" id="parentM">
            <li className="nav-item my-1 py-2 py-sm-0">
            {checkPermissions("Admin") ? (
              <Link
                to={"/dashboard"}
                id="userTooltip"
                className="nav-link text-white text-sm-start sidebar-item"
                aria-current="page"
              >
                <i className="bi bi-person"></i>
                {sidebarOpen ? (
                  <span className="ms-2">Users</span>
                ) : (
                  <ReactTooltip
                    anchorSelect="#userTooltip"
                    place="right"
                    effect="solid"
                  >
                    Users
                  </ReactTooltip>
                )}
              </Link>
            ):null}
            </li>
            {/* } */}
            <li className="nav-item my-1 py-2 py-sm-0">
              <Link
                to={"/department"}
                className="nav-link text-white text-sm-start sidebar-item"
                aria-current="page"
                id="departmentTooltip"
              >
                <i className="bi bi-building text-white"></i>
                {sidebarOpen ? (
                  <span className="ms-2">Departments</span>
                ) : (
                  <ReactTooltip
                    anchorSelect="#departmentTooltip"
                    place="right"
                    effect="solid"
                  >
                    Departments
                  </ReactTooltip>
                )}
              </Link>
            </li>
            <li className="nav-item my-1 py-2 py-sm-0">
              <Link
                to={"/types"}
                className="nav-link text-white text-sm-start sidebar-item"
                aria-current="page"
                id="typeTooltip"
              >
                <i className="bi bi-tag"></i>
                {sidebarOpen ? (
                  <span className="ms-2">Types</span>
                ) : (
                  <ReactTooltip
                    anchorSelect="#typeTooltip"
                    place="right"
                    effect="solid"
                  >
                    Types
                  </ReactTooltip>
                )}
              </Link>
            </li>
            <li className="nav-item my-1 py-2 py-sm-0">
              <Link
                to={"/categories"}
                className="nav-link text-white text-sm-start sidebar-item"
                aria-current="page"
                id="categoryTooltip"
              >
                <i className="bi bi-list"></i>
                {sidebarOpen ? (
                  <span className="ms-2">Categories</span>
                ) : (
                  <ReactTooltip
                    anchorSelect="#categoryTooltip"
                    place="right"
                    effect="solid"
                  >
                    Categories
                  </ReactTooltip>
                )}
              </Link>
            </li>
            <li className="nav-item my-1 py-2 py-sm-0">
              <Link
                to={"/status"}
                className="nav-link text-white text-sm-start sidebar-item"
                aria-current="page"
                id="statusTooltip"
              >
                <i className="bi bi-info-circle-fill"></i>
                {sidebarOpen ? (
                  <span className="ms-2">Status</span>
                ) : (
                  <ReactTooltip
                    anchorSelect="#statusTooltip"
                    place="right"
                    effect="solid"
                  >
                    Status
                  </ReactTooltip>
                )}
              </Link>
            </li>
            <li className="nav-item my-1 py-2 py-sm-0">
              <Link
                to={"/websites"}
                className="nav-link text-white text-sm-start sidebar-item"
                aria-current="page"
                id="websiteTooltip"
              >
                <i className="bi bi-globe"></i>
                {sidebarOpen ? (
                  <span className="ms-2">Websites</span>
                ) : (
                  <ReactTooltip
                    anchorSelect="#websiteTooltip"
                    place="right"
                    effect="solid"
                  >
                    Websites
                  </ReactTooltip>
                )}
              </Link>
            </li>
            <li className="nav-item my-1 py-2 py-sm-0">
              <Link
                to={"/auth/change-password"}
                className="nav-link text-white text-sm-start sidebar-item"
                aria-current="page"
                id="passwordTooltip"
              >
                <i className="bi bi-lock"></i>
                {sidebarOpen ? (
                  <span className="ms-2">Change Password</span>
                ) : (
                  <ReactTooltip
                    anchorSelect="#passwordTooltip"
                    place="right"
                    effect="solid"
                  >
                    Change Password
                  </ReactTooltip>
                )}
              </Link>
            </li>
          </ul>
        </div>
      </div>
      {sidebarOpen ? (
        <Link
          className="m-4 d-flex text-white text-center text-sm-start justify-content-center"
          style={{ whiteSpace: "nowrap" }}
          to={"/"}
        >
          <Logout />
        </Link>
      ) : (
        <div
          onClick={handleSignOut}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              width: "35px",
              height: "35px",
              display: "flex",
              justifyContent: "center",
              cursor: "pointer",
            }}
          >
            <i className="bi bi-box-arrow-right text-white"></i>
          </div>
        </div>
      )}
    </div>
  );
};

export default Sidebar;
