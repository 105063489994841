export   const countryOptions = [
    { label: "Afghanistan", value: "Afghanistan" },
    { label: "Albania", value: "Albania" },
    { label: "Algeria", value: "Algeria" },
    { label: "Andorra", value: "Andorra" },
    { label: "Angola", value: "Angola" },
    { label: "Antigua and Barbuda", value: "Antigua and Barbuda" },
    { label: "Argentina", value: "Argentina" },
    { label: "Armenia", value: "Armenia" },
    { label: "Australia", value: "Australia" },
    { label: "Austria", value: "Austria" },
    { label: "Azerbaijan", value: "Azerbaijan" },
    { label: "Bahamas", value: "Bahamas" },
    { label: "Bahrain", value: "Bahrain" },
    { label: "Bangladesh", value: "Bangladesh" },
    { label: "Barbados", value: "Barbados" },
    { label: "Belarus", value: "Belarus" },
    { label: "Belgium", value: "Belgium" },
    { label: "Belize", value: "Belize" },
    { label: "Benin", value: "Benin" },
    { label: "Bhutan", value: "Bhutan" },
    { label: "Bolivia", value: "Bolivia" },
    { label: "Bosnia and Herzegovina", value: "Bosnia and Herzegovina" },
    { label: "Botswana", value: "Botswana" },
    { label: "Brazil", value: "Brazil" },
    { label: "Brunei", value: "Brunei" },
    { label: "Bulgaria", value: "Bulgaria" },
    { label: "Burkina Faso", value: "Burkina Faso" },
    { label: "Burundi", value: "Burundi" },
    { label: "Cabo Verde", value: "Cabo Verde" },
    { label: "Cambodia", value: "Cambodia" },
    { label: "Cameroon", value: "Cameroon" },
    { label: "Canada", value: "Canada" },
    { label: "Central African Republic", value: "Central African Republic" },
    { label: "Chad", value: "Chad" },
    { label: "Chile", value: "Chile" },
    { label: "China", value: "China" },
    { label: "Colombia", value: "Colombia" },
    { label: "Comoros", value: "Comoros" },
    { label: "Congo (Congo-Brazzaville)", value: "Congo (Congo-Brazzaville)" },
    { label: "Costa Rica", value: "Costa Rica" },
    { label: "Croatia", value: "Croatia" },
    { label: "Cuba", value: "Cuba" },
    { label: "Cyprus", value: "Cyprus" },
    { label: "Czechia (Czech Republic)", value: "Czechia (Czech Republic)" },
    {
      label: "Democratic Republic of the Congo",
      value: "Democratic Republic of the Congo",
    },
    { label: "Denmark", value: "Denmark" },
    { label: "Djibouti", value: "Djibouti" },
    { label: "Dominica", value: "Dominica" },
    { label: "Dominican Republic", value: "Dominican Republic" },
    { label: "Ecuador", value: "Ecuador" },
    { label: "Egypt", value: "Egypt" },
    { label: "El Salvador", value: "El Salvador" },
    { label: "Equatorial Guinea", value: "Equatorial Guinea" },
    { label: "Eritrea", value: "Eritrea" },
    { label: "Estonia", value: "Estonia" },
    {
      label: "Eswatini (fmr. 'Swaziland')",
      value: "Eswatini (fmr. 'Swaziland')",
    },
    { label: "Ethiopia", value: "Ethiopia" },
    { label: "Fiji", value: "Fiji" },
    { label: "Finland", value: "Finland" },
    { label: "France", value: "France" },
    { label: "Gabon", value: "Gabon" },
    { label: "Gambia", value: "Gambia" },
    { label: "Georgia", value: "Georgia" },
    { label: "Germany", value: "Germany" },
    { label: "Ghana", value: "Ghana" },
    { label: "Greece", value: "Greece" },
    { label: "Grenada", value: "Grenada" },
    { label: "Guatemala", value: "Guatemala" },
    { label: "Guinea", value: "Guinea" },
    { label: "Guinea-Bissau", value: "Guinea-Bissau" },
    { label: "Guyana", value: "Guyana" },
    { label: "Haiti", value: "Haiti" },
    { label: "Honduras", value: "Honduras" },
    { label: "Hungary", value: "Hungary" },
    { label: "Iceland", value: "Iceland" },
    { label: "India", value: "India" },
    { label: "Indonesia", value: "Indonesia" },
    { label: "Iran", value: "Iran" },
    { label: "Iraq", value: "Iraq" },
    { label: "Ireland", value: "Ireland" },
    { label: "Israel", value: "Israel" },
    { label: "Italy", value: "Italy" },
    { label: "Jamaica", value: "Jamaica" },
    { label: "Japan", value: "Japan" },
    { label: "Jordan", value: "Jordan" },
    { label: "Kazakhstan", value: "Kazakhstan" },
    { label: "Kenya", value: "Kenya" },
    { label: "Kiribati", value: "Kiribati" },
    { label: "Kosovo", value: "Kosovo" },
    { label: "Kuwait", value: "Kuwait" },
    { label: "Kyrgyzstan", value: "Kyrgyzstan" },
    { label: "Laos", value: "Laos" },
    { label: "Latvia", value: "Latvia" },
    { label: "Lebanon", value: "Lebanon" },
    { label: "Lesotho", value: "Lesotho" },
    { label: "Liberia", value: "Liberia" },
    { label: "Libya", value: "Libya" },
    { label: "Liechtenstein", value: "Liechtenstein" },
    { label: "Lithuania", value: "Lithuania" },
    { label: "Luxembourg", value: "Luxembourg" },
    { label: "Madagascar", value: "Madagascar" },
    { label: "Malawi", value: "Malawi" },
    { label: "Malaysia", value: "Malaysia" },
    { label: "Maldives", value: "Maldives" },
    { label: "Mali", value: "Mali" },
    { label: "Malta", value: "Malta" },
    { label: "Marshall Islands", value: "Marshall Islands" },
    { label: "Mauritania", value: "Mauritania" },
    { label: "Mauritius", value: "Mauritius" },
    { label: "Mexico", value: "Mexico" },
    { label: "Micronesia", value: "Micronesia" },
    { label: "Moldova", value: "Moldova" },
    { label: "Monaco", value: "Monaco" },
    { label: "Mongolia", value: "Mongolia" },
    { label: "Montenegro", value: "Montenegro" },
    { label: "Morocco", value: "Morocco" },
    { label: "Mozambique", value: "Mozambique" },
    { label: "Myanmar (formerly Burma)", value: "Myanmar (formerly Burma)" },
    { label: "Namibia", value: "Namibia" },
    { label: "Nauru", value: "Nauru" },
    { label: "Nepal", value: "Nepal" },
    { label: "Netherlands", value: "Netherlands" },
    { label: "New Zealand", value: "New Zealand" },
    { label: "Nicaragua", value: "Nicaragua" },
    { label: "Niger", value: "Niger" },
    { label: "Nigeria", value: "Nigeria" },
    { label: "North Korea", value: "North Korea" },
    { label: "North Macedonia", value: "North Macedonia" },
    { label: "Norway", value: "Norway" },
    { label: "Oman", value: "Oman" },
    { label: "Pakistan", value: "Pakistan" },
    { label: "Palau", value: "Palau" },
    { label: "Palestine State", value: "Palestine State" },
    { label: "Panama", value: "Panama" },
    { label: "Papua New Guinea", value: "Papua New Guinea" },
    { label: "Paraguay", value: "Paraguay" },
    { label: "Peru", value: "Peru" },
    { label: "Philippines", value: "Philippines" },
    { label: "Poland", value: "Poland" },
    { label: "Portugal", value: "Portugal" },
    { label: "Qatar", value: "Qatar" },
    { label: "Romania", value: "Romania" },
    { label: "Russia", value: "Russia" },
    { label: "Rwanda", value: "Rwanda" },
    { label: "Saint Kitts and Nevis", value: "Saint Kitts and Nevis" },
    { label: "Saint Lucia", value: "Saint Lucia" },
    {
      label: "Saint Vincent and the Grenadines",
      value: "Saint Vincent and the Grenadines",
    },
    { label: "Samoa", value: "Samoa" },
    { label: "San Marino", value: "San Marino" },
    { label: "Sao Tome and Principe", value: "Sao Tome and Principe" },
    { label: "Saudi Arabia", value: "Saudi Arabia" },
    { label: "Senegal", value: "Senegal" },
    { label: "Serbia", value: "Serbia" },
    { label: "Seychelles", value: "Seychelles" },
    { label: "Sierra Leone", value: "Sierra Leone" },
    { label: "Singapore", value: "Singapore" },
    { label: "Slovakia", value: "Slovakia" },
    { label: "Slovenia", value: "Slovenia" },
    { label: "Solomon Islands", value: "Solomon Islands" },
    { label: "Somalia", value: "Somalia" },
    { label: "South Africa", value: "South Africa" },
    { label: "South Korea", value: "South Korea" },
    { label: "South Sudan", value: "South Sudan" },
    { label: "Spain", value: "Spain" },
    { label: "Sri Lanka", value: "Sri Lanka" },
    { label: "Sudan", value: "Sudan" },
    { label: "Suriname", value: "Suriname" },
    { label: "Sweden", value: "Sweden" },
    { label: "Switzerland", value: "Switzerland" },
    { label: "Syria", value: "Syria" },
    { label: "Tajikistan", value: "Tajikistan" },
    { label: "Tanzania", value: "Tanzania" },
    { label: "Thailand", value: "Thailand" },
    { label: "Timor-Leste", value: "Timor-Leste" },
    { label: "Togo", value: "Togo" },
    { label: "Tonga", value: "Tonga" },
    { label: "Trinidad and Tobago", value: "Trinidad and Tobago" },
    { label: "Tunisia", value: "Tunisia" },
    { label: "Turkey", value: "Turkey" },
    { label: "Turkmenistan", value: "Turkmenistan" },
    { label: "Tuvalu", value: "Tuvalu" },
    { label: "Uganda", value: "Uganda" },
    { label: "Ukraine", value: "Ukraine" },
    { label: "United Arab Emirates", value: "United Arab Emirates" },
    { label: "United Kingdom", value: "United Kingdom" },
    { label: "USA", value: "USA" },
    { label: "Uruguay", value: "Uruguay" },
    { label: "Uzbekistan", value: "Uzbekistan" },
    { label: "Vanuatu", value: "Vanuatu" },
    { label: "Vatican City", value: "Vatican City" },
    { label: "Venezuela", value: "Venezuela" },
    { label: "Vietnam", value: "Vietnam" },
    { label: "Yemen", value: "Yemen" },
    { label: "Zambia", value: "Zambia" },
    { label: "Zimbabwe", value: "Zimbabwe" },
  ];
export  const languageOptions = [
    { label: "Afar", value: "Afar" },
    { label: "Abkhazian", value: "Abkhazian" },
    { label: "Avestan", value: "Avestan" },
    { label: "Afrikaans", value: "Afrikaans" },
    { label: "Akan", value: "Akan" },
    { label: "Amharic", value: "Amharic" },
    { label: "Aragonese", value: "Aragonese" },
    { label: "Arabic", value: "Arabic" },
    { label: "Assamese", value: "Assamese" },
    { label: "Avaric", value: "Avaric" },
    { label: "Aymara", value: "Aymara" },
    { label: "Azerbaijani", value: "Azerbaijani" },
    { label: "Bambara", value: "Bambara" },
    { label: "Bashkir", value: "Bashkir" },
    { label: "Basque", value: "Basque" },
    { label: "Belarusian", value: "Belarusian" },
    { label: "Bengali", value: "Bengali" },
    { label: "Bihari", value: "Bihari" },
    { label: "Bislama", value: "Bislama" },
    { label: "Bosnian", value: "Bosnian" },
    { label: "Breton", value: "Breton" },
    { label: "Bulgarian", value: "Bulgarian" },
    { label: "Burmese", value: "Burmese" },
    { label: "Catalan", value: "Catalan" },
    { label: "Chamorro", value: "Chamorro" },
    { label: "Chechen", value: "Chechen" },
    { label: "Chichewa", value: "Chichewa" },
    { label: "Chinese", value: "Chinese" },
    { label: "Chuvash", value: "Chuvash" },
    { label: "Cornish", value: "Cornish" },
    { label: "Corsican", value: "Corsican" },
    { label: "Cree", value: "Cree" },
    { label: "Croatian", value: "Croatian" },
    { label: "Czech", value: "Czech" },
    { label: "Danish", value: "Danish" },
    { label: "Divehi", value: "Divehi" },
    { label: "Dutch", value: "Dutch" },
    { label: "Dzongkha", value: "Dzongkha" },
    { label: "English", value: "English" },
    { label: "Esperanto", value: "Esperanto" },
    { label: "Estonian", value: "Estonian" },
    { label: "Ewe", value: "Ewe" },
    { label: "Faroese", value: "Faroese" },
    { label: "Fijian", value: "Fijian" },
    { label: "Finnish", value: "Finnish" },
    { label: "French", value: "French" },
    { label: "Fulah", value: "Fulah" },
    { label: "Gaelic", value: "Gaelic" },
    { label: "Galician", value: "Galician" },
    { label: "Ganda", value: "Ganda" },
    { label: "Georgian", value: "Georgian" },
    { label: "German", value: "German" },
    { label: "Greek", value: "Greek" },
    { label: "Guarani", value: "Guarani" },
    { label: "Gujarati", value: "Gujarati" },
    { label: "Haitian", value: "Haitian" },
    { label: "Hausa", value: "Hausa" },
    { label: "Hebrew", value: "Hebrew" },
    { label: "Herero", value: "Herero" },
    { label: "Hindi", value: "Hindi" },
    { label: "Hiri Motu", value: "Hiri Motu" },
    { label: "Hungarian", value: "Hungarian" },
    { label: "Icelandic", value: "Icelandic" },
    { label: "Ido", value: "Ido" },
    { label: "Igbo", value: "Igbo" },
    { label: "Indonesian", value: "Indonesian" },
    { label: "Interlingua", value: "Interlingua" },
    { label: "Interlingue", value: "Interlingue" },
    { label: "Inuktitut", value: "Inuktitut" },
    { label: "Inupiaq", value: "Inupiaq" },
    { label: "Irish", value: "Irish" },
    { label: "Italian", value: "Italian" },
    { label: "Japanese", value: "Japanese" },
    { label: "Javanese", value: "Javanese" },
    { label: "Kalaallisut", value: "Kalaallisut" },
    { label: "Kannada", value: "Kannada" },
    { label: "Kanuri", value: "Kanuri" },
    { label: "Kashmiri", value: "Kashmiri" },
    { label: "Kazakh", value: "Kazakh" },
    { label: "Khmer", value: "Khmer" },
    { label: "Kikuyu", value: "Kikuyu" },
    { label: "Kinyarwanda", value: "Kinyarwanda" },
    { label: "Kirghiz", value: "Kirghiz" },
    { label: "Komi", value: "Komi" },
    { label: "Kongo", value: "Kongo" },
    { label: "Korean", value: "Korean" },
    { label: "Kuanyama", value: "Kuanyama" },
    { label: "Kurdish", value: "Kurdish" },
    { label: "Kwanyama", value: "Kwanyama" },
    { label: "Lao", value: "Lao" },
    { label: "Latin", value: "Latin" },
    { label: "Latvian", value: "Latvian" },
    { label: "Limburgish", value: "Limburgish" },
    { label: "Lingala", value: "Lingala" },
    { label: "Lithuanian", value: "Lithuanian" },
    { label: "Luba-Katanga", value: "Luba-Katanga" },
    { label: "Luxembourgish", value: "Luxembourgish" },
    { label: "Macedonian", value: "Macedonian" },
    { label: "Malagasy", value: "Malagasy" },
    { label: "Malay", value: "Malay" },
    { label: "Malayalam", value: "Malayalam" },
    { label: "Maltese", value: "Maltese" },
    { label: "Manx", value: "Manx" },
    { label: "Maori", value: "Maori" },
    { label: "Marathi", value: "Marathi" },
    { label: "Marshallese", value: "Marshallese" },
    { label: "Mongolian", value: "Mongolian" },
    { label: "Nauru", value: "Nauru" },
    { label: "Navajo", value: "Navajo" },
    { label: "Ndonga", value: "Ndonga" },
    { label: "Nepali", value: "Nepali" },
    { label: "North Ndebele", value: "North Ndebele" },
    { label: "Northern Sami", value: "Northern Sami" },
    { label: "Norwegian", value: "Norwegian" },
    { label: "Norwegian Bokmål", value: "Norwegian Bokmål" },
    { label: "Norwegian Nynorsk", value: "Norwegian Nynorsk" },
    { label: "Nuosu", value: "Nuosu" },
    { label: "Occitan", value: "Occitan" },
    { label: "Ojibwa", value: "Ojibwa" },
    { label: "Oriya", value: "Oriya" },
    { label: "Oromo", value: "Oromo" },
    { label: "Ossetian", value: "Ossetian" },
    { label: "Pali", value: "Pali" },
    { label: "Pashto", value: "Pashto" },
    { label: "Persian", value: "Persian" },
    { label: "Polish", value: "Polish" },
    { label: "Portuguese", value: "Portuguese" },
    { label: "Punjabi", value: "Punjabi" },
    { label: "Quechua", value: "Quechua" },
    { label: "Romanian", value: "Romanian" },
    { label: "Romansh", value: "Romansh" },
    { label: "Rundi", value: "Rundi" },
    { label: "Russian", value: "Russian" },
    { label: "Samoan", value: "Samoan" },
    { label: "Sango", value: "Sango" },
    { label: "Sanskrit", value: "Sanskrit" },
    { label: "Sardinian", value: "Sardinian" },
    { label: "Scottish Gaelic", value: "Scottish Gaelic" },
    { label: "Serbian", value: "Serbian" },
    { label: "Shona", value: "Shona" },
    { label: "Sindhi", value: "Sindhi" },
    { label: "Sinhala", value: "Sinhala" },
    { label: "Slovak", value: "Slovak" },
    { label: "Slovenian", value: "Slovenian" },
    { label: "Somali", value: "Somali" },
    { label: "South Ndebele", value: "South Ndebele" },
    { label: "Southern Sotho", value: "Southern Sotho" },
    { label: "Spanish", value: "Spanish" },
    { label: "Sundanese", value: "Sundanese" },
    { label: "Swahili", value: "Swahili" },
    { label: "Swati", value: "Swati" },
    { label: "Swedish", value: "Swedish" },
    { label: "Tagalog", value: "Tagalog" },
    { label: "Tahitian", value: "Tahitian" },
    { label: "Tajik", value: "Tajik" },
    { label: "Tamil", value: "Tamil" },
    { label: "Tatar", value: "Tatar" },
    { label: "Telugu", value: "Telugu" },
    { label: "Thai", value: "Thai" },
    { label: "Tibetan", value: "Tibetan" },
    { label: "Tigrinya", value: "Tigrinya" },
    { label: "Tonga", value: "Tonga" },
    { label: "Tsonga", value: "Tsonga" },
    { label: "Tswana", value: "Tswana" },
    { label: "Turkish", value: "Turkish" },
    { label: "Turkmen", value: "Turkmen" },
    { label: "Twi", value: "Twi" },
    { label: "Uighur", value: "Uighur" },
    { label: "Ukrainian", value: "Ukrainian" },
    { label: "Urdu", value: "Urdu" },
    { label: "Uzbek", value: "Uzbek" },
    { label: "Venda", value: "Venda" },
    { label: "Vietnamese", value: "Vietnamese" },
    { label: "Volapük", value: "Volapük" },
    { label: "Walloon", value: "Walloon" },
    { label: "Welsh", value: "Welsh" },
    { label: "Western Frisian", value: "Western Frisian" },
    { label: "Wolof", value: "Wolof" },
    { label: "Xhosa", value: "Xhosa" },
    { label: "Yiddish", value: "Yiddish" },
    { label: "Yoruba", value: "Yoruba" },
    { label: "Zhuang", value: "Zhuang" },
    { label: "Zulu", value: "Zulu" }
  ];
  