import "./App.css";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Login from "./Components/Auth/login";
import Dashboard from "./Components/Dashboard/dashboard";
import Types from "./Components/Types/types";
import Categories from "./Components/Categories/categories";
import Websites from "./Components/Websites/websites";
import Department from "./Components/Department/department";
import Forgot from "./Components/Auth/Forgot";
import ChangePassword from "./Components/Auth/ChangePassword";
import { useParams } from "react-router-dom";
import AddWebsite from "./Components/AddWebsite/website";
import EditWebsite from "./Components/EditWebsite/editWebsite";
import EditWebsiteRow from "./Components/EditWebsite/editWebsiteRow";
import Status from "./Components/Status/status";

function App() {
  
  const role = localStorage.getItem("permission");
  const authService = {
    isAuthenticated: () => {
      return localStorage.getItem("token") !== null;
    },
  };
  const dashboardService = {
    isAuthenticated: () => {
      return localStorage.getItem("role") !== null;
    },
  };

  const AuthRoute = ({ element, ...rest }) => {
    return authService.isAuthenticated() ? (
      element
    ) : (
      <Navigate to="/" replace />
    );
  };
  const AdminRoute = ({ element, ...rest }) => {
    return authService.isAuthenticated() ? (
      element
    ) : (
      <Navigate to="/" replace />
    );
  };

  return (
    <BrowserRouter>
      <ToastContainer />
      <div>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/forgot" element={<Forgot />} />
          <Route
            path="/reset-password/:token/:email"
            element={<ChangePassword />}
          />
          <Route
            path="/dashboard"
            element={<AuthRoute element={<Dashboard />} />}
          />
          <Route
            path="/addwebsite"
            element={<AuthRoute element={<AddWebsite />} />}
          />
          <Route
            path="/editwebsite"
            element={<AuthRoute element={<EditWebsite />} />}
          />
          <Route
            path="/editwebsiterow"
            element={<AuthRoute element={<EditWebsiteRow />} />}
          />

          <Route path="/types" element={<AuthRoute element={<Types />} />} />
          <Route
            path="/websites"
            element={<AuthRoute element={<Websites />} />}
          />
          <Route
            path="/department"
            element={<AuthRoute element={<Department />} />}
          />
          <Route
            path="/categories"
            element={<AuthRoute element={<Categories />} />}
          />
          <Route
            path="/status"
            element={<AuthRoute element={<Status />} />}
          />
          <Route
            path="/auth/change-password"
            element={<AuthRoute element={<ChangePassword />} />}
          />
        </Routes>
      </div>
    </BrowserRouter>
  );
}
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

export default App;
