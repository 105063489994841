import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { BsPencilSquare, BsTrash, BsPlus } from "react-icons/bs";
import Sidebar from "../Sidebar/sidebar";
import Pagination from "../Pagination";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Circles } from "react-loader-spinner";
import { Card, CardBody, CardFooter, CardHeader } from "react-bootstrap";
import "./typesStyle.css";
import { Footer } from "../Footer/footer";

function Types() {
  const [data, setData] = useState([]);
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [typesPerPage] = useState(5);
  const [selectedTypeId, setSelectedTypeId] = useState(null);
  const [typeName, setTypeName] = useState("");
  const [updateModal, setUpdateModal] = useState(null);
  const [deleteModal, setDeleteModal] = useState(null);
  const [addModal, setAddModal] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [paginationValue, setPaginationValue] = useState({});
  const [selectedRows,  setSelectedRows]=useState([])

  const token = localStorage.getItem("token");
  useEffect(() => {
    listTypes();
  }, [currentPage, searchValue]);

  const listTypes = () => {
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_SERVER_API}/api/type/list?pageNo=${currentPage}&searchValue=${searchValue}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setData(res.data.result.data);
        setPaginationValue(res.data.result.pagination);
      })
      .catch((err) => toast.error(err.response.data.message))
      .finally(() => setLoading(false));
  };
  
  const permission = localStorage.getItem("permission");

  const checkPermissions = (permissionName) => {
    return permission === permissionName;
  }

  const incrementToNextStep = (number) => {
    // Convert number to an integer
    let integerPart = parseInt(number);
    // Add 1 to the integer part
    integerPart++;
    // If there's a decimal part, round it up
    let decimalPart = number - integerPart;
    if (decimalPart > 0) {
        integerPart++;
    }
    return integerPart;
}
  
  const handleUpdate = (id, typeName) => {
    setTypeName(typeName);
    setSelectedTypeId(id);
    if (updateModal) updateModal.show();
  };

  
  const handleDelete = (id) => {
    setSelectedTypeId(id);
    if (deleteModal) deleteModal.show();
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleAdd = () => {
    setSelectedTypeId(null);
    setTypeName("");
    if (updateModal) updateModal.hide();
    if (deleteModal) deleteModal.hide();
    if (addModal) addModal.show();
  };

  const handleUpdateSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_SERVER_API}/api/type/update`,
        {
          id: selectedTypeId,
          typeName,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        const updatedData = data.map((type) =>
          type.id === selectedTypeId ? res.data : type
        );
        setData(updatedData);
        if (updateModal) updateModal.hide();
        toast.success("Type Updated Successfully");
        setLoading(false);
        listTypes();
      })
      .catch((err) => toast.error(err.response.data.message))
      .finally(() => setLoading(false));
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleDeleteSubmit = (e) => {
    e.preventDefault();
    let id = selectedTypeId;
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_SERVER_API}/api/type/delete`,
        { id },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        const updatedData = data.filter((Type) => Type.id !== selectedTypeId);
        setData(updatedData);
        if (deleteModal) deleteModal.hide();
        setSelectedRows([])
        toast.success("Type Deleted Successfully");
        setLoading(false);
        listTypes();
      })
      .catch((err) => toast.error(err.response.data.message))
      .finally(() => setLoading(false));
  };

  const handleAddSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post(
        `${process.env.REACT_APP_SERVER_API}/api/type/add`,
        {
          typeName,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setData([...data, res.data]);
        if (addModal) addModal.hide();
        toast.success("Type Added Succesfully");
        setLoading(false);
        listTypes();
      })
      .catch((err) => toast.error(err.response.data.message))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setUpdateModal(
      new window.bootstrap.Modal(document.getElementById("updateModal"))
    );
    setDeleteModal(
      new window.bootstrap.Modal(document.getElementById("deleteModal"))
    );
    setAddModal(
      new window.bootstrap.Modal(document.getElementById("addModal"))
    );
  }, []);

  const highlightSearchValue = (value) => {
    if (!searchValue) return value;

    const regex = new RegExp(searchValue, "gi");
    return typeof value === "string" ? (
      <span
        dangerouslySetInnerHTML={{
          __html: value.replace(
            regex,
            (match) =>
              `<mark style="background-color: yellow; font-weight: bold;">${match}</mark>`
          ),
        }}
      />
    ) : (
      value
    );
  };

  const handleSelectRow = (id) => {
    if (selectedRows.includes(id)) {
      setSelectedRows(selectedRows.filter((rowId) => rowId !== id));
    } else {
      setSelectedRows([...selectedRows, id]);
    }
  };

  const handleSelectAll = () => {
    if (selectedRows.length === data.length) {
      setSelectedRows([]);
    } else {
      setSelectedRows(data.map((type) => type._id));
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <Sidebar />
        <Circles
          height="80"
          width="80"
          color="#3155a7"
          ariaLabel="bars-loading"
          wrapperStyle={{}}
          wrapperClass="loading-spinner-overlay"
          visible={loading}
        />
        <>
          <div className="col" style={{ height: "100vh", overflow: "auto" }}>
            <div className="mt-3">
              <h3>Types Table</h3>
              <Card>
                <CardHeader>
                  <div className="d-flex justify-content-between" id="header1">
                    <input
                      type="text"
                      autoComplete="off"
                      name="search0"
                      id="search0"
                      style={{
                        height: "40px",
                        borderRadius: "5px",
                        border: "1px solid #dbdbdb",
                        padding: "10px",
                      }}
                      placeholder="Search"
                      onChange={handleSearchChange}
                    />
                  {checkPermissions("Admin") || checkPermissions("Editor") ? (
                    <div>
                    <button
                      onClick={handleAdd}
                      style={{ backgroundColor: "#3155a7", color: "#fff" }}
                      className="btn"
                    >
                      <BsPlus /> Add Types
                    </button>
                    {selectedRows.length >0 &&
                    <button
                    onClick={() => handleDelete(selectedRows)}
                    className="my-1 mx-1 btn btn-sm"
                    style={{
                      padding:"8px",
                      fontSize:"14px",
                      backgroundColor: "rgb(78 89 113)",
                      color: "#fff",
                    }}
                    disabled={selectedRows.length === 0}
                  >
                    Delete Selected
                  </button>}
                  </div>
                  ): null}
                  </div>
                </CardHeader>
                <CardBody>
                  <div className="table-box" style={{ overflowX: "auto",height:'65vh' }}>
                    <table className="table">
                      <thead>
                        <tr>
                        <th>
                            <input
                              type="checkbox"
                              checked={selectedRows.length === data.length}
                              onChange={handleSelectAll}
                            />
                          </th>
                          <th scope="col">Name</th>
                          <th scope="col">Created_At</th>
                          {checkPermissions("Admin") || checkPermissions("Editor") ? (
                          <th scope="col">Actions</th>
                          ): null}
                        </tr>
                      </thead>
                      <tbody>
                        {data.length > 0 ? (
                          <>
                            {data?.map((type) => (
                              <tr key={type._id}>
                                <td>
                                    <input
                                      type="checkbox"
                                      checked={selectedRows.includes(
                                         type._id
                                      )}
                                      onChange={() =>
                                        handleSelectRow(type?._id)
                                      }
                                    />
                                  </td>
                                <td>{highlightSearchValue(type.TypeName)}</td>
                                <td>{highlightSearchValue(type.createdAt)}</td>
                                {checkPermissions("Admin") || checkPermissions("Editor") ? (
                                <td>
                                  <button
                                    onClick={() => {
                                      handleUpdate(
                                        type._id,
                                        type.TypeName,
                                        type.created_at
                                      );
                                    }}
                                    className="btn btn-sm btn-primary my-1 me-2"
                                  >
                                    <BsPencilSquare />
                                  </button>
                                  <button
                                    onClick={() => handleDelete(type._id)}
                                    className="btn btn-sm btn-danger my-1"
                                  >
                                    <BsTrash />
                                  </button>
                                </td>
                                ): null}
                              </tr>
                            ))}
                          </>
                        ) : (
                          <>
                            <tr>
                              <th colSpan={4} className="text-center">
                                No Data Found
                              </th>
                            </tr>
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                </CardBody>
                <CardFooter>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Pagination
                      currentPage={currentPage}
                      data={{'per_page':pageSize, 'total': paginationValue.total}}
                      lastPage={incrementToNextStep(paginationValue.total/pageSize)}
                      onPageChange={handlePageChange}
                    />
                  </div>
                </CardFooter>
              </Card>
            </div>

            <div className="modal" id="updateModal" tabIndex="-1">
              <div className="modal-dialog">
                <div className="modal-content">
                  <form onSubmit={handleUpdateSubmit}>
                    <div className="modal-header">
                      <h5 className="modal-title">Update Type</h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div className="mb-3">
                        <label htmlFor="typeName" className="form-label">
                          Name *
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="typeName"
                          value={typeName}
                          onChange={(e) => setTypeName(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-bs-dismiss="modal"
                      >
                        Close
                      </button>
                      <button type="submit" className="btn btn-primary">
                        Save changes
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="modal" id="deleteModal" tabIndex="-1">
              <div className="modal-dialog">
                <div className="modal-content">
                  <form onSubmit={handleDeleteSubmit}>
                    <div className="modal-header">
                      <h5 className="modal-title">Delete Type</h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <p>Are you sure you want to delete this type?</p>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                      <button type="submit" className="btn btn-danger">
                        Delete
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="modal" id="addModal" tabIndex="-1">
              <div className="modal-dialog">
                <div className="modal-content">
                  <form onSubmit={handleAddSubmit}>
                    <div className="modal-header">
                      <h5 className="modal-title">Add Type</h5>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-body">
                      <div className="mb-3">
                        <label htmlFor="typeName" className="form-label">
                          Name *
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="typeName"
                          value={typeName}
                          onChange={(e) => setTypeName(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-bs-dismiss="modal"
                      >
                        Close
                      </button>
                      <button type="submit" className="btn btn-primary">
                        Add Type
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </>
      </div>
    </div>
  );
}

export default Types;
